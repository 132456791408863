import React, { Component } from "react";
import { Container, Grid2, List, Button, Typography } from "@mui/material";
import { NavLink } from "react-router";

const menuItems = [
  { link: "/", title: "Home", class: "footer-menu-item", isPage: true },
  { link: "/services", title: "Services", class: "footer-menu-item", isPage: true },
  { link: "/projects", title: "Projects", class: "footer-menu-item", isPage: true },
  { link: "/contact", title: "Contact Us", class: "footer-menu-item", isPage: true }
];
let navlInks = menuItems.map(item => (
  <NavLink
    key={item.link}
    to={item.link}
    className={item.class}
  >
    {item.title}
  </NavLink>

));

class Footer extends Component {
  render() {
    return (
      <footer style={{ position: "relative", bottom: 0, left: 0, right: 0 }}>
        <Container>
          <Grid2 container justifyContent="space-between" className="footer-grid">
            <Grid2 size={{ xs: 12, md: 4 }} item className="footer-item footer-talk-item">
              <Typography variant="h3" className="headline" color="white">
                Let's Talk
              </Typography>
              <Typography variant="h5">
                Every project starts with a conversation. We will be happy to discuss your ideas and help you make them a reality.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className="header-button"
                href="https://calendly.com/tsolakidis_k/30min"
                target="_blank"
              >
                Tell us about your project
              </Button>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }} item className="footer-item">
              <Typography variant="h6" gutterBottom>SITEMAP</Typography>
              <List sx={{ display: "grid" }}>
                {navlInks}
              </List>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }} item className="footer-item">
              <a href="/" className="footer-logo">
                <img className="App-logo" src="/imgs/logo-white.png" alt="WeAreFabbrik" />
              </a>
              <span className="marginTop25">info@wearefabbrik.com</span>
              <span>Sepapaja tn 6, Tallinn</span>
              <span>Estonia</span>
              <a className="footer-logo" href="https://ee.linkedin.com/company/wearefabbrik?trk=public_profile_topcard-current-company" target="_blank" rel="noopener noreferrer">LI</a>
            </Grid2>
          </Grid2>
          <div className="trademark">
            © 2025 by WeAreFabbrik OÜ. All rights reserved
          </div>
          {/* <div className="privacy-policy">
            <Link href="/policy" color="inherit">
              {"Privacy Policy"}
            </Link>
          </div> */}
        </Container>
      </footer>
    );
  }
}
export default Footer;
