import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { inject } from '@vercel/analytics';
import { BrowserRouter, Routes, Route } from "react-router";
import Projects from './pages/Projects';
import Services from './pages/Services';
import Contact from './pages/Contact';
import { SpeedInsights } from "@vercel/speed-insights/react";

const root = createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/services' element={<Services />} />
            <Route path='/contact' element={<Contact />} />
        </Routes>
        <SpeedInsights projectId="speed-insights-4b1b1" />
    </BrowserRouter>
)

inject();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
